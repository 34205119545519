import { parser } from "./parser.js"
import { foldNodeProp, indentNodeProp, delimitedIndent, LRLanguage } from '@codemirror/language';
import { NodeProp } from '@lezer/common'
import { styleTags, tags as _tags, Tag } from "@lezer/highlight"

const edgeSpecifier = Tag.define(_tags.modifier);
const portSpecifier = Tag.define(_tags.modifier);
const netTypeName = Tag.define(_tags.typeName);
const taskName = _tags.function(_tags.name);
const primitiveType = Tag.define(_tags.typeName);
const moduleName = Tag.define(_tags.className);
const attribute = Tag.define(_tags.annotation);
const systemIdentifier = _tags.special(_tags.variableName);
const systemTaskOrFunctionName = _tags.function(systemIdentifier);
const builtinPrimitiveType = _tags.standard(primitiveType);
const userPrimitiveType = _tags.special(primitiveType);
const parameterName = _tags.constant(_tags.name);

export const tags = {
    ..._tags,
    edgeSpecifier,
    portSpecifier,
    netTypeName,
    primitiveType,
    moduleName,
    attribute,
    systemIdentifier,
    userPrimitiveType,
    builtinPrimitiveType,
    parameterName
};

const tagAssociations = {
    /* Keywords */
    Keyword: _tags.keyword,
    'EdgeSymbol! LevelSymbol/Identifier!': edgeSpecifier,
    'localparam parameter': _tags.definitionKeyword,
    'module macromodule endmodule': _tags.moduleKeyword,
    'function endfunction task endtask': _tags.definitionKeyword,
    'primitive endprimitive': _tags.definitionKeyword,
    'begin end': _tags.keyword,
    /* Sensitivities */
    '@': _tags.controlOperator,
    'edge posedge negedge': edgeSpecifier,
    /* Control keywords */
    'always initial': _tags.controlKeyword,
    'for while': _tags.controlKeyword,
    'if else': _tags.controlKeyword,
    'case casex casez endcase': _tags.controlKeyword,
    /* Primitives and stuff */
    'cmos rcmos': builtinPrimitiveType,
    'bufif0 bufif1 notif0 notif1': builtinPrimitiveType,
    'nmos pmos rnmos rpmos': builtinPrimitiveType,
    'and nand or nor xor xnor': builtinPrimitiveType,
    'supply0 supply1 tri triand trior tri0 tri1 uwire wire wand wor reg': netTypeName,
    'integer real genvar': _tags.typeName,
    /* Modifiers */
    'input output inout': portSpecifier,
    'signed unsigned': _tags.modifier,
    /* Names */
    Identifier: _tags.variableName,
    'AttrName!': attribute,
    'SystemIdentifier': systemIdentifier,
    'VariableIdentifier!': _tags.variableName,
    'DirectiveName!': _tags.processingInstruction,
    'UDPIdentifier': userPrimitiveType,
    'UDPDeclaration/UDPIdentifier!': _tags.definition(userPrimitiveType),
    'ModuleIdentifier!': moduleName,
    'ModuleDeclaration/ModuleIdentifier!': _tags.definition(moduleName),
    'ModuleInstanceIdentifier! UDPInstanceIdentifier!': _tags.definition(_tags.name),
    'SystemTaskIdentifier! SystemFunctionIdentifier!': systemTaskOrFunctionName,
    'ParamAssignment/ParameterIdentifier!': _tags.definition(parameterName),
    'VariableIdentifier! OutputPortIdentifier!': _tags.variableName,
    'r R f F p P n N b B': _tags.variableName,
    /* Constant literals */
    UnsignedNumber: _tags.number,
    RealNumber: _tags.float,
    DecimalValue: _tags.integer,
    BinaryValue: _tags.integer,
    OctalValue: _tags.integer,
    HexValue: _tags.integer,
    TimescaleValue: _tags.number,
    'x X z Z': _tags.number,
    String: _tags.string,
    StringEscape: _tags.escape,
    'CaseItem/default CaseGenerateItem/default': _tags.special(_tags.labelName),
    /* Comments */
    'BlockComment BlockComment/"*/"': _tags.blockComment,
    LineComment: _tags.lineComment,
    'DocComment DocComment/"*/"': _tags.docComment,
    /* Brackets */
    '( )': _tags.paren,
    '[ ]': _tags.angleBracket,
    '(* *)': _tags.special(_tags.paren),
    '{ }': _tags.brace,
};

/**
 * 
 * @template T
 * @template U
 * @param {(key: keyof T, value: T[key], object: T) => U} func 
 * @param {T} obj 
 * @returns {{ [key: string]: U }}
 */
const transform = (func, obj) => {
    /** @type {{ [key: string]: U }} */
    const dst = {};
    for (const key in obj)
        dst[key] = func(key, obj[key], obj);
    return dst;
}

/**
 * @type {{ [open: string]: string }}
 */
export const foldingPairs = {
    'module': 'endmodule',
    'macromodule': 'endmodule',
    'casex': 'endcase',
    'casez': 'endcase',
    'case': 'endcase',
    'begin': 'end',
    'fork': 'join',
    'config': 'endconfig',
    'table': 'endtable',
    'generate': 'endgenerate',
    'function': 'endfunction',
    'primitive': 'endprimitive',
    'task': 'endtask',
    '(*': '*)',
    '/*': '*/',
    '/**': '*/',
    '(': ')',
    '[': ']',
    '{': '}'
};

export const folding = foldNodeProp.add({
    [
        'ModuleDeclaration'
        + ' ConfigDeclaration'
        + ' FunctionDeclaration'
        + ' TaskDeclaration'
        + ' UDPDeclaration'
    ]: context => {
        const sc = context.getChild(';');
        const lc = context.lastChild;
        if (!sc || !lc)
            return null;

        return { from: sc.to, to: lc.from - 1};
    },
    [
        'GenerateRegion'
        + ' UDPBody'
        + ' SpecifyBlock'
    ]: context => {
        const fc = context.firstChild;
        const lc = context.lastChild;
        if (!fc || !lc)
            return null;

        return { from: fc.to, to: lc.from };
    },
    'CaseGenerateConstruct CaseStatement': context => {
        const fc = context.getChild(')');
        const lc = context.lastChild;
        if (!fc || !lc)
            return null;

        return { from: fc.to, to: lc.from };
    },
    'GenerateBlock': context => {
        const fc = context.getChild('GenerateBlockIdentifier')
                ?? context.firstChild;
        const lc = context.lastChild;
        if (!fc || !lc)
            return null;

        return { from: fc.to, to: lc.from };
    },
    'ParBlock SeqBlock': context => {
        const fc = (context.getChild('Statement') ?? context.lastChild)?.prevSibling;
        const lc = context.lastChild;
        if (!fc || !lc)
            return null;

        return { from: fc.to, to: lc.from };
    }
});
export const closedBy = NodeProp.closedBy.add(transform((_, close) => [close], foldingPairs))
export const styling = styleTags(tagAssociations);

const augmentedParser = parser.configure({
    props: [ 
        styling, 
        closedBy,
        folding,
        indentNodeProp.add({
            'PortDeclarationList PortList ModuleParameterPortList'
                : delimitedIndent({ closing: ')' }),
            'ModuleItem' : ctx => ctx.textAfter.match(/^(case|begin|fork)/)
                                ? ctx.baseIndent 
                                : ctx.baseIndent + ctx.unit,
            'SeqBlock' : delimitedIndent({ closing: 'end' }),
            'GenerateRegion' : delimitedIndent({ closing: 'endgenerate' }),
            'ParBlock' : delimitedIndent({ closing: 'join' }),
            'ModuleItemList' : delimitedIndent({ closing:'endmodule'})
        }),
    ]
})

export const verilogLanguage = LRLanguage.define({
    parser: augmentedParser,
    languageData: {
        'closeBrackets': {
            brackets: Object.keys(foldingPairs)
        }
    }
});

export default verilogLanguage;