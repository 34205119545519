
import { LanguageSupport } from "@codemirror/language"
import { completeFromList, ifIn, ifNotIn, snippetCompletion, snippet, closeBrackets } from '@codemirror/autocomplete'
import language from "./language.js"


/**
 * 
 * @param {import("@codemirror/autocomplete").CompletionSource[]} sources 
 */
const completions = sources => sources.map(source => language.data.of({ autocomplete: source }))
export const verilogCompletion = [
    ...completions([
        ifNotIn([
                'Description', 
                'LineComment', 
                'BlockComment',
                'DocComment',
                'String'
            ], 
            completeFromList([
                snippetCompletion(
                    'module ${module_name} (${});\n\t${}\nendmodule',
                    { label: 'module' }
                ),
                snippetCompletion(
                    'module ${module_name} #(${}) (${});\n\t${}\nendmodule',
                    { label: 'module', detail: 'with parameters' }
                ),
                snippetCompletion(
                    'module ${0}_tb;\n\treg clock = 0;\n\treg reset = 1;\n\t${1}\n\n\t'
                    + '${0} DUT ();\n\n\talways #5 clock <= !clock;\nendmodule',
                    { label: 'module', detail: 'testbench' }
                )
            ])),
        ifIn(['ModuleItemList'], 
            ifNotIn([
                'SpecifyBlock',
                'SeqBlock',
                'ParBlock'
            ], 
            completeFromList([
                { label: 'parameter', apply: snippet('parameter ${0} = ${1:0};') },
                { label: 'always', detail: 'sequential', 
                    apply: snippet('always @(posedge ${clock}) begin\n\t${}\nend') },
                { label: 'always', detail: 'sync. reset', 
                    apply: snippet('always @(posedge ${clock})\nif (${reset}) begin\n\t\nend else begin\n\t\nend') },
                { label: 'always', detail: 'combinational',
                    apply: snippet('always @(${*}) begin\n\t${}\nend') },
                { label: 'reg' },
                { label: 'wire' },
            ])
            )),
        ifIn(['ModuleParameterPortList'], completeFromList([ 
            snippetCompletion('parameter ${0} = ${1:0}',
                { label: 'parameter' })
        ])),
        completeFromList([
            { label: 'begin', type: 'keyword', apply: snippet('begin\n\t${}\nend') },
            { label: 'begin', type: 'keyword', detail: 'with label', apply: snippet('begin : ${}\n\t${}\nend') }
        ])
    ])
];

/**
 * 
 * @param {object} [config]
 * @param {boolean} [config.completion = true] Enable autocompletion support
 * @returns 
 */
export function verilog({ completion = true } = {}) {
    return new LanguageSupport(
        language, 
        [
            completion && verilogCompletion,
            closeBrackets()
        ].flat()
         .filter(
                /** @returns {x is import("@codemirror/state").Extension} */
                x => x !== false
        ));
}

